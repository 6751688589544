import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        bookings: {data: [],per_page: 25,total: 0},
        loading: false
    },
    getters: {
        bookings: state => state.bookings,
        loading: state => state.loading
    },
    mutations: {
        setBookings(state,data){
            state.bookings = data
        },
        setLoading(state,loading){
            state.loading = loading
        }
    },
    actions: {
        async getBookings({commit},payload){
            try {
                commit('setLoading',true)
                const response = await api.getBookings(payload)
                commit('setBookings',response);
                commit('setLoading',false)
            }catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        }
    }
}
