import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        contacts: {data: [], total: 0, per_page: 25},
        contact_details: {data: [], total: 0, per_page: 25},
        loading: false
    },
    getters: {
        contacts: state => state.contacts,
        contact_details: state => state.contact_details,
        loading: state => state.loading
    },
    mutations: {
        setContacts(state, data) {
            state.contacts = data
        },
        setContactDetails(state, data) {
            state.contact_details = data
        },
        setLoading(state,status){
            state.loading = status
        }
    },
    actions: {
        async getContactDetails({commit}, payload) {
            try {
                commit('setLoading',true);
                const response = await api.getContactDetails(payload);
                commit('setContactDetails', response)
                commit('setLoading',false);
            } catch (e) {
                commit('setLoading',false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getContacts({commit}, payload) {
            try {
                commit('setLoading',true);
                const response = await api.getContacts(payload);
                commit('setContacts', response)
                commit('setLoading',false);
            } catch (e) {
                commit('setLoading',false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createContactDetail(context, payload) {
            return await api.createContactDetail(payload);
        },
        async uploadContactDetails(context, payload) {
            return await api.uploadContactDetails(payload);
        },
        async createContact(context, payload) {
            return await api.createContact(payload)
        },
        async deleteContactDetail({dispatch}, payload) {
            try {
                await api.deleteContactDetail(payload)
                dispatch('getContactDetails', {
                    bulk_contact_id: payload.bulk_contact_id
                })
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async updateContact(context, payload) {
            return await api.updateContact(payload);
        },
        async deleteContact({dispatch}, payload) {
            try {
                await api.deleteContact(payload);
                dispatch('getContacts', {})
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }
    }
}
