import client from "@/data/api/client";

export default {
    async getCashMovements(payload) {
        return client.parseResponse(await client.get('/cash-movement', payload))
    },
    async exportCashMovements(payload) {
        return client.parseResponse(await client.post('/export-cash-movement', payload, {responseType: 'blob'}))
    },
    async getRevenueReport(payload) {
        return client.parseResponse(await client.get('/revenue-report', payload))
    },
    async getBulkSMSReport(payload) {
        return client.parseResponse(await client.get('/bulk-sms-report', payload))
    },
    async getBookingReport(payload) {
        return client.parseResponse(await client.get('/booking-report', payload))
    }
}
