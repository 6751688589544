import api from "@/data/api";

export default {
    namespaced: true, state: {
        bulk_sms: {data: [], per_page: 50, total: 0},
        bulk_sms_contacts: {data: [], per_page: 50, total: 0},
        sms: {},
        loading: false,
        bulk_contacts: []
    }, getters: {
        bulk_sms: state => state.bulk_sms,
        bulk_sms_contacts: state => state.bulk_sms_contacts,
        sms: state => state.sms,
        loading: state => state.loading,
        bulk_contacts: state => state.bulk_contacts
    }, mutations: {
        setBulkSms(state, data) {
            state.bulk_sms = data;
        }, setBulkSmsContacts(state, data) {
            state.bulk_sms_contacts = data;
        }, setSms(state, data) {
            state.sms = data;
        }, setLoading(state, status) {
            state.loading = status;
        },
        setBulkContacts(state, data) {
            state.bulk_contacts = data;
        }
    }, actions: {
        async getBulkSms({commit}, payload) {
            try {
                commit('setLoading',true)
                const response = await api.getBulkSms(payload)
                commit('setBulkSms', response)
                commit('setLoading',false)
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        }, async createBulkSms(context, payload) {
            try {
                return api.createBulkSms(payload)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async updateBulkSms(context, payload) {
            try {
                return api.updateBulkSms(payload)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async sendBulkSms({dispatch}, payload) {
            try {
                await api.sendBulkSms(payload)
                dispatch('getBulkSms', {})
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async getSms({commit}, payload) {
            try {
                const response = await api.getSms(payload)
                commit('setSms', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async getBulkSmsContact({commit}, payload) {
            try {
                commit('setLoading',true)
                const response = await api.getBulkSmsContact(payload)
                commit('setBulkSmsContacts', response)
                commit('setLoading',false)
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async uploadContacts(context, payload) {
            return await api.uploadContacts(payload)
        },
        async createBulkSmsContact(context, payload) {
            try {
                return await api.createBulkSmsContact(payload)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async deleteBulkSmsContact({dispatch}, payload) {
            try {
                await api.deleteBulkSmsContact(payload)
                this._vm.$message.success('contact removed successfully!')
                dispatch('getBulkSmsContact', {
                    bulk_sms_id: payload.bulk_sms_id
                })
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async deleteBulkSms({dispatch}, payload) {
            try {
                await api.deleteBulkSms(payload)
                this._vm.$message.success('Bulk sms removed successfully!')
                dispatch('getBulkSms', {})
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getBulkContacts({commit}, payload) {
            try {
                const response = await api.getBulkContacts(payload);
                commit('setBulkContacts', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async saveContactsFromBulkContacts({dispatch},payload){
            try {
                await api.saveContactsFromBulkContacts(payload)
                this._vm.$message.success('contacts added successfully!')
                dispatch('getBulkSmsContact', {
                    bulk_sms_id: payload.bulk_sms_id
                })
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }
    }

}
