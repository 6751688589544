import client from "@/data/api/client";

export default {
    async getCountries(payload) {
        return client.parseResponse(await client.get('/country', payload))
    },
    async getAals(payload) {
        return client.parseResponse(await client.get('/aal', payload))
    },
    async sendInquiry(payload) {
        return client.parseResponse(await client.post('/inquiry', payload));
    },
    async getCompanies(payload) {
        return client.parseResponse(await client.get('/company', payload))
    },
    async getKeywords(payload) {
        return client.parseResponse(await client.get('/keyword', payload))
    },
    async getSubActivities(payload) {
        return client.parseResponse(await client.get('/sub-activity', payload))
    },
    async getAmenities(payload) {
        return client.parseResponse(await client.get('/amenity', payload))
    }
}
