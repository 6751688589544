import store from '../../data/store';
const redirectMiddleware = (to, next) => {
    setTimeout(() => {
        const loggedIn = store.getters['auth/loggedIn'];
        if (loggedIn) {
            window.location.href = '/dashboard';
            return next(false);
        }
        return next();
    }, 100)
};
export default redirectMiddleware;
