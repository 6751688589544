import client from "@/data/api/client";

export default {
    async getBlog(payload) {
        return client.parseResponse(await client.get(`/blog/${payload.slug}`, payload))
    },
    async getBlogs(payload) {
        return client.parseResponse(await client.get('/blog', payload))
    },
    async createBlog(payload) {
        return client.parseResponse(await client.upload('/blog', payload, payload.file))
    },
    async updateBlog(payload) {
        return client.parseResponse(await client.upload(`/blog/${payload.id}`, payload, payload.file))
    },
    async deleteBlog(payload) {
        return client.parseResponse(await client.delete(`/blog/${payload.id}`))
    },
    async getBlogCategories(payload) {
        return client.parseResponse(await client.get('/blog-category', payload))
    }
}
