export default [
    {
        path: '/',
        name: 'home page',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/home-page'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/dashboard/dashboard-page'),
        },
        meta: {
            auth: true
        },
    }
]
