import api from "@/data/api";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        invite_error: null,
        team_invitation: null
    },
    getters: {
        loggedIn() {
            return !!window.localStorage.getItem('ve@token');
        },
        team_invitation: state => state.team_invitation,
        invite_error: state => state.invite_error
    },
    mutations: {
        setTeamInvitation(state,data){
            state.team_invitation = data
        },
        setTeamInvitationError(state,data){
            state.invite_error = data
        }
    },
    actions: {
        async signUp(context, payload) {
            try {
                const {message} = await api.signUp(payload)
                this._vm.$message.success(message)
                await router.push('/verify-email')
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async signIn(context, payload) {
            try {
                const {token} = await api.signIn(payload)
                localStorage.setItem('ve@token', token)
                let next = localStorage.getItem('next');
                if (next){
                    localStorage.removeItem('next')
                }
                window.location.href =  next ? next : '/dashboard'
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
                if (e.response.data.code && e.response.data.code === 403) {
                    await router.push('/verify-email')
                }
            }
        },
        async signOut(context, payload) {
            try {
                await api.signOut(payload)
                localStorage.removeItem('ve@token')
                window.location.href = '/sign-in'
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async resetPassword(context, payload) {
            try {
                const {message} = await api.resetPassword(payload)
                this._vm.$message.success(message)
                await router.push('/reset-email')
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async recoverPassword(context, payload) {
            try {
                const {message} = await api.recoverPassword(payload)
                this._vm.$message.success(message)
                await router.push('/sign-in')
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getInvitation({commit},payload){
            try {
                const response = await api.getInvitation(payload)
                commit('setTeamInvitation',response)
            } catch (e) {
                commit('setTeamInvitationError',e.response.data.message)
            }
        },
        async updateInvitation(context,payload){
            return api.updateInvitation(payload)
        }
    }
}
