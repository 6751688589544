<template>
    <div class="sticky z-30 top-0 flex flex-col">
        <div v-show="$route.meta && $route.meta.showPlayStoreBanner && bannerVisible"
             class="relative bg-gradient-to-r from-[#F5A73B] to-[#1E3F8C] py-2">
            <div class="flex flex-col lg:flex-row items-center justify-center px-3 space-x-3">
                <div class="flex items-center">
                    <span class="text-4xl mr-2" role="img" aria-label="Celebration Emoji">🎉</span>
                    <p class="text-white text-lg">We are now on Play Store!</p>
                </div>
                <a href="https://play.google.com/store/apps/details?id=com.vacay.experience" target="_blank"
                   rel="noopener noreferrer"
                   class="bg-white text-blue-500 hover:bg-gray-100 py-2 px-4 rounded-full text-sm font-semibold">Download
                    Now</a>
            </div>
            <button @click="closeBanner" type="button" class="absolute top-0 right-0 p-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6 text-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
        <div class="w-full shadow-md">
            <div class="bg-[#F5A73B]">
                <div class="container mx-auto px-4">
                    <div class="flex items-center justify-between py-4 w-full">
                        <router-link to="/" class="flex flex-col items-center text-white">
                            <img class="w-[30px] h-[30px]" src="@/assets/logos/logo-icon-white.svg" alt="">
                            <span class=" text-sm font-semibold">Vacay Experience</span>

                        </router-link>

                        <div class="hidden lg:flex sm:items-center xl:space-x-14 space-x-8 text-white">
                            <router-link to="/" class="text-sm font-semibold hover:text-blue-500 ">Home
                            </router-link>
                            <router-link v-if="loggedIn" to="/dashboard"
                                         class=" text-sm font-semibold hover:text-blue-500 ">Dashboard
                            </router-link>
                            <router-link to="/explore-packages" class=" text-sm font-semibold hover:text-blue-500 ">
                                Explore Packages
                            </router-link>
                            <router-link to="/explore-events" class=" text-sm font-semibold hover:text-blue-500 ">
                                Explore Events
                            </router-link>
                            <router-link to="/package/destinations" class=" text-sm font-semibold hover:text-blue-500 ">
                                Travel Destinations
                            </router-link>
                            <router-link to="/operators" class=" text-sm font-semibold hover:text-blue-500 ">Tour
                                Operators
                            </router-link>
                            <router-link to="/pricing" class=" text-sm font-semibold hover:text-blue-500 ">Pricing
                            </router-link>
                        </div>

                        <div class="flex flex-row items-center justify-center gap-x-1">
                            <div v-if="!loggedIn" class="hidden lg:flex sm:items-center">
                                <router-link to="/sign-in"
                                             class="py-2 px-7 font-semibold text-sm border rounded-md bg-blue-500 text-white mr-4">
                                    Login
                                </router-link>
                                <router-link to="/sign-up"
                                             class="text-blue-500 text-sm font-semibold border border-blue-500 px-4 py-2 rounded-lg  hover:text-white hover:bg-blue-500">
                                    Start Free Trial
                                </router-link>
                            </div>

                            <div id="dropdown-content" v-if="loggedIn" class="relative inline-block text-left">
                                <div>
                                    <span @click="$store.commit('setProfileDropdownHidden',!profile_dropdown_hidden)"
                                          class="cursor-pointer flex flex-col items-center justify-center mr-2 w-14 h-14 rounded-full border border-white">
                                <span
                                    class="text-white font-extrabold">{{
                                        profile.name ? (profile.name.split(' ').length ? profile.name.split(' ')[0].charAt(0).toUpperCase() : '') : ''
                                    }}</span>
                                    </span>
                                </div>
                                <transition enter-active-class="transition ease-out duration-100"
                                            enter-from-class="transform opacity-0 scale-95"
                                            enter-to-class="transform opacity-100 scale-100"
                                            leave-active-class="transition ease-in duration-75"
                                            leave-from-class="transform opacity-100 scale-100"
                                            leave-to-class="transform opacity-0 scale-95">
                                    <div v-bind:class="{'hidden': profile_dropdown_hidden}"
                                         class="absolute right-0 z-10 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                         role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
                                         tabindex="-1">
                                        <div class="flex flex-col items-center justify-center py-1" role="none">
                                            <span
                                                class="cursor-pointer flex flex-col items-center justify-center mr-2 w-14 h-14 rounded-full border border-green-900">
                                        <span
                                            class="text-gray-700 font-extrabold">{{
                                                profile.name ? profile.name.split(' ').map(i => i.charAt(0).toUpperCase()).join('') : ''
                                            }}</span>
                                            </span>
                                            <p class="text-center text-gray-700 block px-4 py-2 text-sm">{{
                                                    profile.name
                                                }}
                                                -
                                                <b>{{ profile.company ? profile.company.account_number : '' }}</b> <br>
                                                ({{ profile.email }})</p>
                                        </div>
                                        <div class="py-1" role="none">
                                            <router-link to="/profile" class="text-gray-700 block px-4 py-2 text-sm"
                                                         role="menuitem" tabindex="-1" id="menu-item-2">Company Profile
                                            </router-link>
                                        </div>
                                        <div class="py-1" role="none">
                                            <router-link to="/wallet" class="text-gray-700 block px-4 py-2 text-sm"
                                                         role="menuitem"
                                                         tabindex="-1" id="menu-item-4">Wallet
                                                ({{ balance ? balance.toFixed(2) : 0 }} USD)
                                            </router-link>
                                            <router-link to="/settings" class="text-gray-700 block px-4 py-2 text-sm"
                                                         role="menuitem"
                                                         tabindex="-1" id="menu-item-4">Settings
                                            </router-link>
                                            <router-link to="/billing"
                                                         class="flex flex-row w-full items-center justify-between text-gray-700 block px-4 py-2 text-sm"
                                                         role="menuitem"
                                                         tabindex="-1" id="menu-item-5"><span>Billing</span> <span
                                                v-if="profile.company && profile.company.subscription_plan"
                                                v-bind:class="{'bg-red-600': profile.company.subscription_plan.status !== 'active','bg-green-600': profile.company.subscription_plan.status === 'active'}"
                                                class="p-1 border rounded-md text-white text-xs">{{
                                                    profile.company.subscription_plan.plan.name
                                                }}</span>
                                            </router-link>
                                            <router-link to="/business/verification"
                                                         class="flex flex-row w-full items-center justify-between text-gray-700 block px-4 py-2 text-sm"
                                                         role="menuitem" tabindex="-1" id="menu-item-5">
                                                <span>Business Verification</span>
                                                <span
                                                    v-bind:class="{'bg-red-600': verification.id && verification.status === 'Rejected','bg-green-600': verification.id && verification.status === 'Approved','bg-gray-500': verification.status === 'Pending','bg-red-500': !verification.id}"
                                                    class="p-1 border rounded-md text-white text-xs font-bold">
                                                        {{ !verification.id ? 'Pending' : verification.status }}
                                                    </span>
                                            </router-link>
                                        </div>
                                        <div class="py-1" role="none">
                                            <button @click="logout"
                                                    class="cursor-pointer hover:text-accent focus:text-accent hover:bg-green-50 flex items-center space-x-2 rounded-lg px-6 py-3 transition-colors w-full">
                                                <i class="fa-solid fa-right-from-bracket mr-2 text-green-base"></i>
                                                <span class="font-display text-black/80 text-sm">Sign Out</span>
                                            </button>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                            <div class="lg:hidden flex flex-row space-x-2">
                                <div class="mr-2">
                                    <button @click="toggleMobileMenu" class="flex-none px-2 ">
                                        <svg width="35" height="16" viewBox="0 0 35 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg" class="hamburger-light">
                                            <line x1="2" y1="2" x2="33" y2="2" stroke="white" stroke-width="4"
                                                  stroke-linecap="round"></line>
                                            <line x1="13" y1="14" x2="33" y2="14" stroke="white" stroke-width="4"
                                                  stroke-linecap="round"></line>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <header
            :class="!mobile_menu_hidden ? 'navbar-open' : 'navbar-close'"
            class="navbar w-full min-h-3/4 fixed overflow-x-scroll bg-white border border-gray-200 top-0 z-50 lg:invisible bg-slate">
            <div class="flex pr-2 pt-7 justify-end">
                <button @click="toggleMobileMenu()" class="p-4 text-white text-xl font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                         stroke="black"
                         class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                    </svg>

                </button>
            </div>
            <div class="flex flex-col items-center space-y-6 mx-12 py-8">
                <router-link to="/" class=" text-sm font-semibold hover:text-blue-500 ">Home
                </router-link>
                <router-link v-if="loggedIn" to="/dashboard" class=" text-sm font-semibold hover:text-blue-500 ">
                    Dashboard
                </router-link>
                <router-link to="/explore-packages" class=" text-sm font-semibold hover:text-blue-500 ">Explore Packages
                </router-link>
                <router-link to="/explore-events" class=" text-sm font-semibold hover:text-blue-500 ">Explore Events
                </router-link>
                <router-link to="/package/destinations" class=" text-sm font-semibold hover:text-blue-500 ">
                    Travel Destinations
                </router-link>
                <a href="/#features" @click="globalScrollToDiv('/','features')"
                   class=" text-sm font-semibold hover:text-blue-500 ">Features</a>
                <router-link to="/pricing" class=" text-sm font-semibold hover:text-blue-500 ">Pricing</router-link>
                <router-link to="/operators" class=" text-sm font-semibold hover:text-blue-500 ">Tour Operators
                </router-link>
            </div>

            <div v-if="!loggedIn" class="flex flex-row w-full justify-center">
                <router-link to="/sign-in"
                             class="py-2 px-7 font-semibold text-sm border rounded-md bg-blue-500 text-white mr-4">Login
                </router-link>
                <router-link to="/sign-up"
                             class="text-blue-500 text-sm font-semibold border border-blue-500 px-4 py-2 rounded-lg  hover:text-white hover:bg-blue-500">
                    Start
                    Free Trial
                </router-link>
            </div>

        </header>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    mounted() {
        if (this.loggedIn) {
            this.getProfile({}).then(() => {
                this.getWalletBalance({company_id: this.profile.company.id})
            })
            this.getCompanyRoles({})
            this.getBusinessVerification({})
        }
        document.addEventListener('click', (e) => {
            const dropdown = document.getElementById('dropdown-content');
            if (dropdown) {
                if (dropdown.contains(e.target)) return; // if the clicked element is inside the dropdown or it's the dropdown itself, do nothing
                if (!this.profile_dropdown_hidden) {
                    this.$store.commit('setProfileDropdownHidden', true);
                }
            }
        })
    },
    computed: {
        ...mapGetters({
            profile_dropdown_hidden: 'profile_dropdown_hidden',
            profile: 'profile/profile',
            loggedIn: 'auth/loggedIn',
            mobile_menu_hidden: 'mobile_menu_hidden',
            balance: 'wallet/balance',
            verification: 'profile/verification',
            company_roles: 'profile/company_roles'
        })
    },
    data() {
        return {
            visible: true,
            bannerVisible: localStorage.getItem('bannerVisible') ? JSON.parse(localStorage.getItem('bannerVisible')) : true
        }
    },
    methods: {
        ...mapActions({
            getProfile: 'profile/getProfile',
            signOut: 'auth/signOut',
            getWalletBalance: 'wallet/getWalletBalance',
            getBusinessVerification: 'profile/getBusinessVerification',
            getCompanyRoles: 'profile/getCompanyRoles'
        }),
        logout() {
            this.signOut({});
        },
        toggleMobileMenu() {
            this.$store.commit('setMobileMenuHidden', !this.mobile_menu_hidden)
        },
        closeBanner() {
            this.bannerVisible = false;
            localStorage.setItem('bannerVisible', JSON.stringify(false))
        }
    }
}
</script>
<style>
.navbar {
    transition: all 330ms ease-out;
}

.navbar-open {
    transform: translateY(0%);
}

.navbar-close {
    transform: translateY(-100%);
}
</style>
