import api from "@/data/api";

export default {
    namespaced: true, state: {
        meta_posts: {data: [], per_page: 20, total: 0},
        post_comments: {data: [], per_page: 20, total: 0},
        page_profile: {},
        loading: false
    }, getters: {
        loading: state => state.loading,
        page_profile: state => state.page_profile,
        meta_posts: state => state.meta_posts,
        post_comments: state => state.post_comments
    }, mutations: {
        setLoading(state, data) {
            state.loading = data;
        },
        setPageProfile(state, data) {
            state.page_profile = data;
        },
        setMetaPosts(state, data) {
            state.meta_posts = data;
        },
        setPostComments(state, data) {
            state.post_comments = data;
        }
    }, actions: {
        async sendWhatsappMessage(context, payload) {
            return await api.sendWhatsappMessage(payload)
        },
        async markMessageAsRead(context, payload) {
            return await api.markMessageAsRead(payload);
        },
        async sendWhatsappTemplate(context, payload) {
            return await api.sendWhatsappTemplate(payload)
        },
        async sendMessageReaction(context, payload) {
            return api.sendMessageReaction(payload);
        },
        async sendWhatsappFile(context, payload) {
            return api.sendWhatsappFile(payload)
        },
        async createMetaPost(context, payload) {
            return api.createMetaPost(payload);
        },
        async getMetaPosts({commit}, payload) {
            try {
                const response = await api.getMetaPosts(payload);
                commit('setMetaPosts', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getPostComments({commit}, payload) {
            try {
                commit('setPostComments', {data: [], per_page: 20, total: 0})
                commit('setLoading', true)
                const response = await api.getPostComments(payload)
                commit('setPostComments', response)
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async postCommentReply(context, payload) {
            return api.postCommentReply(payload);
        },
        async postComment(context, payload) {
            return api.postComment(payload);
        },
        async getPageProfile({commit}, payload) {
            try {
                commit('setLoading', true)
                const response = await api.getPageProfile(payload)
                commit('setPageProfile', response)
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
    }
}
