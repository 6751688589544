import api from "@/data/api";
import router from "@/router";
export default {
    namespaced: true,
    state: {
        loading: false,
        events: {data: [],per_page: 10,total: 0},
        events_calendar:{},
        event: {
            from_price: {
                currency: {}
            }
        },
        grouped_events: {
            data:{},
            from_price: {
                currency: {}
            }
        },
    },
    getters: {
        loading: state => state.loading,
        events: state => state.events,
        event: state => state.event,
        grouped_events: state => state.grouped_events,
        events_calendar: state => state.events_calendar,
    },
    mutations: {
        setLoading(state,status){
            state.loading = status;
        },
        setEvents(state,data){
            state.events = data;
        },
        setEvent(state,data){
            state.event = data;
        },
        setGroupedEvents(state,data){
            state.grouped_events = data;
        },
        setEventsCalendar(state,data){
            state.events_calendar = data;
        },
    },
    actions: {
        async createEvent({commit},payload){
            try {
                commit('setLoading',true)
                const {message} = await api.createEvent(payload)
                this._vm.$message.success(message)
                commit('setLoading',false)
                await router.push('/events')
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async updateEvent({commit},payload){
            try {
                commit('setLoading',true)
                const {message} = await api.updateEvent(payload)
                this._vm.$message.success(message)
                commit('setLoading',false)
                await router.push('/events')
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async guestGetEvents({commit},payload){
            try {
                commit('setLoading',true)
                const response = await api.guestGetEvents(payload)
                commit('setEvents',response)
                commit('setLoading',false)
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getEvents({commit},payload){
            try {
                commit('setLoading',true)
                const response = await api.getEvents(payload)
                commit('setEvents',response)
                commit('setLoading',false)
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async guestGetEvent({commit},payload){
            try {
                commit('setLoading',true)
                const response = await api.guestGetEvent(payload)
                commit('setEvent',response)
                commit('setLoading',false)
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getEvent({commit},payload){
            try {
                commit('setLoading',true)
                const response = await api.getEvent(payload)
                commit('setEvent',response)
                commit('setLoading',false)
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async deleteEvent({dispatch},id){
            try {
                await api.deleteEvent(id)
                dispatch('getEvents',{})
            }catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async updateEventPublishedStatus({dispatch},id){
            try {
                await api.updateEventPublishedStatus(id)
                dispatch('getEvents',{})
            }catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async updateEventCoverImage(context,payload){
            await api.updateEventCoverImage(payload)
        },
        async getGroupedEvents({commit},payload){
            try {
                commit('setLoading',true)
                const response = await api.getGroupedEvents(payload)
                commit('setGroupedEvents',response)
                commit('setLoading',false)
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getEventsCalendar({commit},payload){
            try {
                commit('setLoading',true)
                const response = await api.getEventsCalendar(payload)
                commit('setEventsCalendar',response)
                commit('setLoading',false)
            } catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
    }
}
