import client from "@/data/api/client";

export default {
    async getPlans(payload) {
        return client.parseResponse(await client.get('/plan', payload))
    },
    async getPlan(id) {
        return client.parseResponse(await client.get('/plan/' + id))
    },
    async subscribeCompany(payload) {
        return client.parseResponse(await client.post('/subscription', payload))
    },
    async cancelSubscription(payload){
        return client.parseResponse(await client.get('/subscription/'+payload.code, payload))
    }
}
