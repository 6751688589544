import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        card_settings: null,
        mpesa_settings: null,
        loading: false,
        notification_settings: [],
        facebook_pages: [],
        banks: [],
        payment_settings: null,
        meta_whatsapp_accounts: [],
        whatsapp_templates: []
    },
    getters: {
        card_settings: state => state.card_settings,
        mpesa_settings: state => state.mpesa_settings,
        loading: state => state.loading,
        notification_settings: state => state.notification_settings,
        facebook_pages: state => state.facebook_pages,
        banks: state => state.banks,
        payment_settings: state => state.payment_settings,
        meta_whatsapp_accounts: state => state.meta_whatsapp_accounts,
        whatsapp_templates: state => state.whatsapp_templates
    },
    mutations: {
        setCardSettings(state, payload) {
            state.card_settings = payload;
        },
        setMpesaSettings(state, payload) {
            state.mpesa_settings = payload;
        },
        setLoading(state, status) {
            state.loading = status
        },
        setNotificationSettings(state, data) {
            state.notification_settings = data;
        },
        setFacebookPages(state, data) {
            state.facebook_pages = data;
        },
        setBanks(state, data) {
            state.banks = data;
        },
        setPaymentSettings(state, data) {
            state.payment_settings = data
        },
        setMetaBusinessAccounts(state, data) {
            state.meta_whatsapp_accounts = data;
        },
        setWhatsappTemplates(state, data) {
            state.whatsapp_templates = data;
        }
    },
    actions: {
        async saveWhatsAppPhoneSetupRequest(context,payload){
          return await api.saveWhatsAppPhoneSetupRequest(payload);
        },
        async saveWhatsappTemplate(context, payload) {
            return await api.saveWhatsappTemplate(payload);
        },
        async resumableUpload(context,payload){
            return await api.resumableUpload(payload)
        },
        async getWhatsappTemplates({commit}, payload) {
            const response = await api.getWhatsappTemplates(payload)
            commit('setWhatsappTemplates', response)
        },
        async getMetaWhatsappAccounts({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getMetaWhatsappAccounts(payload)
                commit('setMetaBusinessAccounts', response)
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getPaymentSettings({commit}, payload) {
            try {
                const response = await api.getPaymentSettings(payload)
                commit('setPaymentSettings', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createCardSettings(context, payload) {
            try {
                const {message} = await api.createCardSettings(payload)
                this._vm.$message.success(message)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createMpesaSettings(context, payload) {
            try {
                const {message} = await api.createMpesaSettings(payload)
                this._vm.$message.success(message)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getCardSettings({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getCardSettings(payload);
                commit('setCardSettings', response);
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getNotificationSettings({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getNotificationSettings(payload);
                commit('setNotificationSettings', response);
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getMpesaSettings({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getMpesaSettings(payload);
                commit('setMpesaSettings', response);
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async updateNotificationSettings(context, payload) {
            try {
                await api.updateNotificationSettings(payload);
                this._vm.$message.success('Setting updated successfully!')
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async savePaymentSettings(context, payload) {
            return await api.savePaymentSettings(payload);
        },
        async getBanks({commit}, payload) {
            try {
                commit('setLoading', true);
                const {data} = await api.getBanks(payload);
                commit('setBanks', data);
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async unsubscribeFromWebhooks(context, payload) {
            return await api.unsubscribeFromWebhooks(payload);
        },
        async saveWhatsappToken({dispatch}, payload) {
            try {
                const {message} = await api.saveWhatsappToken(payload);
                dispatch('getMetaWhatsappAccounts', {})
                this._vm.$message.success(message)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async saveWhatsAppSetupRequest(context,payload){
            return await api.saveWhatsAppSetupRequest(payload);
        },
        async saveMetaSettings(context, payload) {
            try {
                await api.saveMetaSettings(payload);
                this._vm.$message.success('Meta settings created successfully!')
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getMetaSettings({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getMetaSettings(payload);
                commit('setFacebookPages', response);
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        }
    }
}
