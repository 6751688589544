export default [
    {
        path: '/package/destinations',
        name: 'package-destinations',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/destinations/package-destination-list.vue'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true
        }
    },
    {
        path: '/package/destinations/:slug',
        name: 'package-destinations-detail',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/destinations/package-destination.vue'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true,
        }
    },
    {
        path: '/destinations',
        name: 'destinations',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/destinations/destination-list'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
    {
        path: '/destinations/create/:id?',
        name: 'destination-create',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/destinations/destination-create'),
        },
        meta: {
            auth: true
        },
    },
    {
        path: '/destinations/gallery/:id',
        name: 'destination-gallery',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/destinations/destination-gallery'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    }
]
