import client from "@/data/api/client";

export default {
    async getPackageDestinations(payload){
        return client.parseResponse(await client.get('/package-destination', payload))
    },
    async getPackageDestination(payload){
        return client.parseResponse(await client.get('/package-destination/'+payload.id, payload))
    },
    async getCompanyDestinations(payload) {
        return client.parseResponse(await client.get('/company-destination', payload))
    },
    async getDestinationTypes(payload) {
        return client.parseResponse(await client.get('/destination-type', payload))
    },
    async deleteCompanyDestination(payload) {
        return client.parseResponse(await client.delete('/company-destination/' + payload.id))
    },
    async getCompanyDestination(payload) {
        return client.parseResponse(await client.get('/company-destination/' + payload.id, payload))
    },
    async getDestinations(payload) {
        return client.parseResponse(await client.get('/destination', payload))
    },
    async createDestination(payload) {
        return client.parseResponse(await client.post('/destination', payload))
    },
    async createCompanyDestination(payload) {
        return client.parseResponse(await client.upload('/company-destination', payload, payload.cover_image))
    },
    async updateCompanyDestination(payload) {
        return client.parseResponse(await client.upload('/company-destination/update/' + payload.id, payload, payload.cover_image))
    },
    async deleteCompanyDestinationImage(payload) {
        return client.parseResponse(await client.delete('/company-destination-gallery/' + payload.id))
    }
}
