import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.store'
import profile from './profile.store'
import destinations from './destinations.store'
import packages from './packages.store'
import bulk_sms from './bulk_sms.store'
import surveys from './surveys.store';
import contacts from './contacts.store'
import bookings from './bookings.store';
import socials from './socials.store';
import general from './general.store';
import wallet from './wallet.store';
import pricing from './pricing.store';
import settings from './settings.store';
import reports from './reports.store';
import explore from './explore.store';
import events from './events.store'
import blogs from './blogs.store'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        profile_dropdown_hidden: true,
        mobile_menu_hidden: true
    },
    getters: {
        profile_dropdown_hidden: state => state.profile_dropdown_hidden,
        mobile_menu_hidden: state => state.mobile_menu_hidden
    },
    mutations: {
        setProfileDropdownHidden(state, status) {
            state.profile_dropdown_hidden = status;
        },
        setMobileMenuHidden(state, status) {
            state.mobile_menu_hidden = status;
        }
    },
    actions: {},
    modules: {
        auth,
        profile,
        destinations,
        packages,
        bulk_sms,
        surveys,
        contacts,
        bookings,
        socials,
        general,
        wallet,
        pricing,
        settings,
        reports,
        explore,
        events,
        blogs
    }
})
