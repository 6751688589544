export default [
    {
        path: '/operators',
        name: 'operators',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/operators/operators-list'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true,
        },
    },
    {
        path: '/operator/:id',
        name: 'operator',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/operators/operator-page.vue'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true,
        },
    },
]
