export default [
    {
        path: '/settings',
        name: 'settings',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/settings/company-settings'),
        },
        meta: {
            auth: true
        },
    },
    {
        path: '/billing',
        name: 'billing',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/settings/company-billing'),
        },
        meta: {
            auth: true
        },
    }
];
