import client from "./client"

export default {
    async getPaymentSettings(payload) {
        return client.parseResponse(await client.get('/payment-setting', payload))
    },
    async getMetaWhatsappAccounts(payload) {
        return client.parseResponse(await client.get('/meta-whatsapp-account', payload));
    },
    async unsubscribeFromWebhooks(payload) {
        return client.parseResponse(await client.delete('/meta-whatsapp-account/' + payload.account_id))
    },
    async getWhatsappTemplates(payload) {
        return client.parseResponse(await client.get('/whatsapp-template', payload));
    },
    async saveWhatsappTemplate(payload) {
        return client.parseResponse(await client.post('/whatsapp-template', payload))
    },
    async saveWhatsAppPhoneSetupRequest(payload){
        return client.parseResponse(await client.post('/whatsapp-phone-setup', payload))
    },
    async resumableUpload(payload) {
        return client.parseResponse(await client.upload('/resumable-upload', payload, payload.file))
    },
    async createCardSettings(payload) {
        return client.parseResponse(await client.post('/card-setting', payload));
    },
    async getCardSettings(payload) {
        return client.parseResponse(await client.get('/card-setting', payload))
    },
    async getMpesaSettings(payload) {
        return client.parseResponse(await client.get('/mpesa-setting', payload))
    },
    async getBanks(payload) {
        return client.parseResponse(await client.get('/bank', payload));
    },
    async savePaymentSettings(payload) {
        return client.parseResponse(await client.post('/payment-setting', payload));
    },
    async createMpesaSettings(payload) {
        return client.parseResponse(await client.post('/mpesa-setting', payload));
    },
    async getNotificationSettings(payload) {
        return client.parseResponse(await client.get('/notification-setting', payload))
    },
    async updateNotificationSettings(payload) {
        return client.parseResponse(await client.put('/notification-setting/' + payload.id, payload))
    },
    async saveMetaSettings(payload) {
        return client.parseResponse(await client.post('/meta-setting', payload));
    },
    async saveWhatsappToken(payload) {
        return client.parseResponse(await client.post('/whatsapp-token', payload));
    },
    async saveWhatsAppSetupRequest(payload){
        return client.parseResponse(await client.post('/whatsapp-phone-setup', payload));
    },
    async getMetaSettings(payload) {
        return client.parseResponse(await client.get('/meta-setting', payload));
    }
}
