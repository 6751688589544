import client from "@/data/api/client";

export default {
    async getAllPackages(payload) {
        return client.parseResponse(await client.get('/packages', payload));
    },
    async getCompanies(payload) {
        return client.parseResponse(await client.get('/company', payload));
    },
    async getCompany(payload){
      return client.parseResponse(await client.get('/company/'+payload.id))
    },
    async getCurrencies(payload){
      return client.parseResponse(await client.get('/currency',payload))
    },
    async convertCurrency(payload){
        return client.parseResponse(await client.get('/convert-currency',payload))
    },
    async getSubActivities(payload) {
        return client.parseResponse(await client.get('/sub-activity', payload));
    },
    async getCountries(payload) {
        return client.parseResponse(await client.get('/country', payload));
    },
}
