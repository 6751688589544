import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        countries: [],
        currencies: [],
        aals: [],
        company: {
            website: '',
            packages: [],
            destination_countries: []
        },
        companies: {data: [], total: 0, per_page: 20},
        loading: false,
        keywords: null,
        sub_activities: [],
        amenities: []
    },
    getters: {
        currencies: state => state.currencies,
        company: state => state.company,
        sub_activities: state => state.sub_activities,
        countries: state => state.countries,
        aals: state => state.aals,
        companies: state => state.companies,
        loading: state => state.loading,
        keywords: state => state.keywords,
        amenities: state => state.amenities
    },
    mutations: {
        setCurrencies(state,data){
          state.currencies = data;
        },
        setCompany(state,data){
          state.company = data;
        },
        setCountries(state, data) {
            state.countries = data;
        },
        setAals(state, data) {
            state.aals = data;
        },
        setCompanies(state, data) {
            state.companies = data;
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setKeywords(state, data) {
            state.keywords = data;
        },
        setSubActivities(state, data) {
            state.sub_activities = data;
        },
        setAmenities(state, data) {
            state.amenities = data;
        }
    }
    ,
    actions: {
        async convertCurrency(context,payload){
            return await api.convertCurrency(payload);
        },
        async getCurrencies({commit},filters){
            const response = await api.getCurrencies(filters);
            commit('setCurrencies',response);
        },
        async getCompany({commit},filters){
            const response = await api.getCompany(filters);
            commit('setCompany',response);
        },
        async getSubActivities({commit}, filters) {
            try {
                const response = await api.getSubActivities(filters);
                commit('setSubActivities', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getCompanies({commit}, filters) {
            try {
                commit('setLoading', true)
                const response = await api.getCompanies(filters);
                commit('setCompanies', response)
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getCountries({commit}, filters) {
            try {
                const response = await api.getCountries(filters)
                commit('setCountries', response);
            } catch (e) {
                return this._vm.$message.error(e.response.data.message);
            }
        },
        async getAmenities({commit}, filters) {
            try {
                const response = await api.getAmenities(filters)
                commit('setAmenities', response);
            } catch (e) {
                return this._vm.$message.error(e.response.data.message);
            }
        },
        async getAals({commit}, filters) {
            try {
                const response = await api.getAals(filters)
                commit('setAals', response);
            } catch (e) {
                return this._vm.$message.error(e.response.data.message);
            }
        },
        async sendInquiry(context, payload) {
            return await api.sendInquiry(payload);
        },
        async getKeywords({commit}, payload) {
            const {keywords} = await api.getKeywords(payload)
            commit('setKeywords', keywords)
        }
    }
}
