export default [
    {
        path: '/explore-packages',
        name: 'explore',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/explore/explore-list.vue'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true,
        },
    },
    {
        path: '/package-details/:id/:slug',
        name: 'package details',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/explore/explore-details.vue'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true,
        },
    },
    {
        path: '/packages',
        name: 'packages',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/packages/package-list'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
        props: true
    },
    {
        path: '/packages/booking/:id',
        name: 'package-booking',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/packages/package-booking'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true
        },
    },
    {
        path: '/packages/create/:id?',
        name: 'package-create',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/packages/package-create'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
];
