import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        surveys: {data: [], per_page: 25, total: 0},
        survey_questions: {data: [], per_page: 25, total: 0},
        survey: {},
        survey_summarized_report: [],
        loading: false,
        flows: []
    },
    getters: {
        surveys: state => state.surveys,
        survey_questions: state => state.survey_questions,
        survey: state => state.survey,
        survey_summarized_report: state => state.survey_summarized_report,
        loading: state => state.loading,
        flows: state =>state.flows
    },
    mutations: {
        setFlows(state,data){
          state.flows = data
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setSurveys(state, data) {
            state.surveys = data;
        },
        setSurveyQuestions(state, data) {
            state.survey_questions = data;
        },
        setSurvey(state, data) {
            state.survey = data;
        },
        setSurveySummarizedReport(state, data) {
            state.survey_summarized_report = data
        }
    },
    actions: {
        async getSurvey({commit}, payload) {
            try {
                const response = await api.getSurvey(payload);
                commit('setSurvey', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createSurveyResponse(context, payload) {
            try {
                await api.createSurveyResponse(payload)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createSurvey(context, payload) {
            try {
                return await api.createSurvey(payload)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createWhatsAppFlow(context,payload){
          return await api.createWhatsAppFlow(payload);
        },
        async publishWhatsAppFlow(context,payload){
            return await api.publishWhatsAppFlow(payload);
        },
        async updateWhatsAppFlow(context,payload){
            return await api.updateWhatsAppFlow(payload);
        },
        async updateSurvey(context, payload) {
            try {
                return await api.updateSurvey(payload)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getFlows({commit},payload){
          try {
              const response = await api.getFlows(payload);
              commit('setFlows',response)
          }catch (e) {
              this._vm.$message.error(e.response.data.message)
          }
        },
        async getSurveys({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getSurveys(payload)
                commit('setSurveys', response)
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getSurveySummarizedReport({commit}, payload) {
            try {
                const response = await api.getSurveySummarizedReport(payload)
                commit('setSurveySummarizedReport', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async deleteSurvey({dispatch}, payload) {
            try {
                const {message} = await api.deleteSurvey(payload)
                dispatch('getSurveys', {})
                this._vm.$message.success(message)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createSurveyQuestion(context, payload) {
            try {
                return await api.createSurveyQuestion(payload)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getSurveyQuestions({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getSurveyQuestions(payload)
                commit('setSurveyQuestions', response)
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async updateSurveyQuestion(context, payload) {
            return api.updateSurveyQuestion(payload)
        },
        async deleteSurveyQuestion({dispatch}, payload) {
            try {
                const {message} = await api.deleteSurveyQuestion(payload);
                this._vm.$message.success(message)
                dispatch('getSurveyQuestions', {
                    survey_id: payload.survey_id
                })
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }
    }
}
