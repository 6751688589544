export default [
    {
        path: '/bookings',
        name: 'bookings',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/bookings/bookings-list'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
    {
        path: '/bookings/:id/payment',
        name: 'booking-payment',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/bookings/booking-payment'),
        },
        meta: {
            guest: true
        },
    },
];
