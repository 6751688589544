import api from "@/data/api";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        blogs: {data: [],per_page: 10,total: 0},
        blog_categories: [],
        blog: {},
        loading: false
    },
    getters: {
        blog_categories: state => state.blog_categories,
        blogs: state => state.blogs,
        loading: state => state.loading,
        blog: state => state.blog
    },
    mutations: {
        setBlog(state,blog){
          state.blog = blog
        },
        setBlogs(state,blogs){
            state.blogs = blogs
        },
        setLoading(state,status){
            state.loading = status;
        },
        setBlogCategories(state,categories){
            state.blog_categories = categories;
        }
    },
    actions: {
        async getBlogs({commit},payload){
            try {
                commit('setLoading',true)
                const response = await api.getBlogs(payload)
                commit('setBlogs',response)
                commit('setLoading',false)
            }catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getBlogCategories({commit},payload){
            try {
                const response = await api.getBlogCategories(payload)
                commit('setBlogCategories',response)
            }catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async deleteBlog(context,payload){
          await api.deleteBlog(payload);
        },
        async updateBlog({commit},payload){
            try {
                commit('setLoading',true)
                await api.updateBlog(payload)
                if (router.currentRoute.name!=='blog'){
                    await router.push('/blog')
                }
                commit('setLoading',false)
            }catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createBlog({commit},payload){
            try {
                commit('setLoading',true)
                await api.createBlog(payload)
                await router.push('/blog')
                commit('setLoading',false)
            }catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getBlog({commit},payload){
            try {
                commit('setLoading',true)
                const response = await api.getBlog(payload)
                commit('setBlog',response)
                commit('setLoading',false)
            }catch (e) {
                commit('setLoading',false)
                this._vm.$message.error(e.response.data.message)
            }
        }
    }
}
