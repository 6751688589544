import api from "@/data/api";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        packages: {data: [], total: 0, per_page: 25},
        package: {company: {},company_destinations: []},
        loading: false,
        measurements: [],
        booking: {}
    },
    getters: {
        packages: state => state.packages,
        package: state => state.package,
        loading: state => state.loading,
        measurements: state => state.measurements,
        booking: state => state.booking
    },
    mutations: {
        setPackages(state, data) {
            state.packages = data;
        },
        setPackage(state, data) {
            state.package = data;
        },
        setLoading(state, status) {
            state.loading = status;
        },
        setMeasurements(state, measurements) {
            state.measurements = measurements;
        },
        setBooking(state, booking) {
            state.booking = booking;
        }
    },
    actions: {
        async createMessage(context, payload) {
            return api.createMessage(payload)
        },
        async getBooking({commit}, id) {
            try {
                const response = await api.getBooking(id)
                commit('setBooking', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async updateBooking(context, payload) {
            return await api.updateBooking(payload);
        },
        async checkPaymentReceipt(context, payload) {
            return api.checkPaymentReceipt(payload);
        },
        async checkSubscriptionReceipt(context, payload) {
            return api.checkSubscriptionReceipt(payload);
        },
        async createSTKPush(context, payload) {
            return await api.createSTKPush(payload)
        },
        async createPackage({dispatch}, payload) {
            try {
                const {message} = await api.createPackage(payload)
                this._vm.$message.success(message)
                dispatch('getPackages', {})
                await router.push('/packages')
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createMeasure(context, payload) {
            return await api.createMeasure(payload);
        },
        async updateMeasure(context,payload){
            return await api.updateMeasure(payload);
        },
        async deleteMeasure(context,payload){
            return await api.deleteMeasure(payload);
        },
        async updatePackage(context, payload) {
            try {
                const {message} = await api.updatePackage(payload)
                this._vm.$message.success(message)
                await router.push('/packages')
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getPackages({commit}, payload) {
            try {
                commit('setLoading', true)
                const response = await api.getPackages(payload)
                commit('setPackages', response)
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async updatePackageCoverImage(context,payload){
          return api.updatePackageCoverImage(payload)
        },
        async togglePublishedStatus(context, payload) {
            await api.togglePublishedStatus(payload);
        },
        async getMeasurements({commit}, payload) {
            try {
                if (payload.load){
                    commit('setLoading', true)
                }
                const response = await api.getMeasurements(payload)
                commit('setMeasurements', response)
                commit('setLoading', false)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
                commit('setLoading', false)
            }
        },
        async deletePackage({dispatch}, payload) {
            try {
                const {message} = await api.deletePackage(payload)
                dispatch('getPackages', {})
                this._vm.$message.success(message)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getPackage({commit}, payload) {
            try {
                commit('setLoading', true)
                const response = await api.getPackage(payload)
                commit('setPackage', response)
                commit('setLoading', false)

            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async sendBookingCode(context, payload) {
            return await api.sendBookingCode(payload)
        },
        async savePayment(context, payload) {
            try {
                return await api.savePayment(payload)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createBooking(context, payload) {
            return await api.createBooking(payload)

        },
        async createShareViaBulkSms(context, payload) {
            try {
                const {message} = await api.createShareViaBulkSms(payload);
                this._vm.$message.success(message)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async createBulkPushNotification(context,payload){
            return await api.createBulkPushNotification(payload);
        }
    }
}
