export default [
    {
        path: '/wallet',
        name: 'wallet',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/wallet/wallet-topup'),
        },
        meta: {
            auth: true
        },
    },
    {
        path: '/yearly/subscription',
        name: 'yearly subscription',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/subscriptions/yearly-subscription.vue'),
        },
        meta: {
            auth: true
        },
    }
]
