import store from '../../data/store';

const authMiddleware = (to, next) => {
    setTimeout(() => {
        const loggedIn = store.getters['auth/loggedIn'];
        const company_roles = JSON.parse(localStorage.getItem('company_roles'))
        const company = JSON.parse(localStorage.getItem('company'))

        if (!loggedIn) {
            localStorage.setItem('next', to.fullPath)
            window.location.href = '/sign-in';
            return next(false);
        }
        if (loggedIn) {
            if (company_roles && !company_roles.logo && to.name !== 'profile setup') {
                window.location.href = '/profile';
            }
            if (company_roles && company_roles.logo){
                if (company && !company.is_subscribed && to.name !== 'yearly subscription') {
                    window.location.href = '/yearly/subscription'
                }
                if (company && company.is_subscribed) {
                    if (to.meta.subscribed) {
                        let index = company_roles.subscription_plan ? company_roles.subscription_plan.plan.roles.findIndex(k => k.role.name === to.name) : -1
                        if (index === -1) {
                            window.location.href = '/upgrade/disclaimer';
                        }
                    } else {
                        const path = localStorage.getItem('path')
                        if (path) {
                            let pathObject = JSON.parse(path);
                            if (pathObject.name !== to.name) {
                                localStorage.removeItem('path')
                                window.location.href = pathObject.path
                            }
                        }
                    }
                }
            }
            return next();
        }
    }, 1000)
};
export default authMiddleware;
