export default [
    {
        path: '/upgrade/disclaimer',
        name: 'upgrade disclaimer',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/general/upgrade-disclaimer'),
        },
        meta: {
            auth: true,
        },
    },
    {
        path: '/investor-relations',
        name: 'investor relations',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/general/investor-relations.vue'),
        },
        meta: {
            guest: true,
        },
    }
];
