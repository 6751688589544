import api from "@/data/api";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        company_destinations: {data: [], total: 0, per_page: 25},
        destinations: {data: [], total: 0, per_page: 25},
        package_destinations: {data: [], total: 0, per_page: 25},
        company_destination: {gallery: []},
        destination: {},
        package_destination: {},
        loading: false,
        destination_types: []
    },
    getters: {
        company_destinations: state => state.company_destinations,
        destinations: state => state.destinations,
        destination: state => state.destination,
        package_destination: state => state.package_destination,
        loading: state => state.loading,
        company_destination: state => state.company_destination,
        destination_types: state => state.destination_types,
        package_destinations: state => state.package_destinations
    },
    mutations: {
        setCompanyDestinations(state, data) {
            state.company_destinations = data
        }, setCompanyDestination(state, data) {
            state.company_destination = data;
        },
        setDestinations(state, data) {
            state.destinations = data
        },
        setDestination(state, data) {
            state.destination = data
        },
        setPackageDestination(state, data) {
            state.package_destination = data;
        },
        setLoading(state, status) {
            state.loading = status
        },
        setDestinationTypes(state, data) {
            state.destination_types = data;
        },
        setPackageDestinations(state, data) {
            state.package_destinations = data;
        }
    },
    actions: {
        async getDestinationTypes({commit}, payload) {
            try {
                const response = await api.getDestinationTypes(payload);
                commit('setDestinationTypes', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getPackageDestination({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getPackageDestination(payload)
                commit('setPackageDestination', response)
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getPackageDestinations({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getPackageDestinations(payload)
                commit('setPackageDestinations', response)
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getCompanyDestinations({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getCompanyDestinations(payload)
                commit('setCompanyDestinations', response)
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                this._vm.$message.error(e.response.data.message)
            }
        },
        async deleteCompanyDestination({dispatch}, payload) {
            try {
                const {message} = await api.deleteCompanyDestination(payload)
                this._vm.$message.success(message)
                dispatch('getCompanyDestinations', {})
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async getCompanyDestination({commit}, payload) {
            try {
                const response = await api.getCompanyDestination(payload)
                commit('setCompanyDestination', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async getDestinations({commit}, payload) {
            try {
                const response = await api.getDestinations(payload)
                commit('setDestinations', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async createDestination(context, payload) {
            try {
                return await api.createDestination(payload)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async updateCompanyDestination({dispatch}, payload) {
            try {
                await api.updateCompanyDestination(payload)
                dispatch('getCompanyDestinations', {})
                await router.push('/destinations')
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async createCompanyDestination({dispatch}, payload) {
            try {
                await api.createCompanyDestination(payload)
                dispatch('getCompanyDestinations', {})
                await router.push('/destinations')
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }, async deleteCompanyDestinationImage({dispatch}, payload) {
            try {
                await api.deleteCompanyDestinationImage(payload)
                dispatch('getCompanyDestination', {id: payload.company_destination_id})
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        }
    }
}
