import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        packages: {data: [], total: 0, per_page: 25},
        companies: {data: [], total: 0, per_page: 25},
        sub_activities: [],
        countries: [],
        loading: false,
        package_loading: false,
    },
    getters: {
        packages: state => state.packages,
        companies: state => state.companies,
        sub_activities: state => state.sub_activities,
        countries: state => state.countries,
        loading: state => state.loading,
        package_loading: state => state.package_loading
    },
    mutations: {
        setPackages(state, data) {
            state.packages = data
        },
        setCompanies(state, data) {
            state.companies = data
        },
        setSubActivities(state, data) {
            state.sub_activities = data
        },
        setCountries(state, data) {
            state.countries = data
        },
        setLoading(state, data) {
            state.loading = data
        },
        setPackageLoading(state, data) {
            state.package_loading = data
        }
    },
    actions: {
        async getPackages({commit}, payload) {
            try {
                commit('setPackages', [])
                commit('setPackageLoading', true)
                const response = await api.getAllPackages(payload);
                commit('setPackages', response)
                commit('setPackageLoading', false)
            } catch (e) {
                commit('setPackageLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getCompanies({commit}, payload) {
            try {
                const response = await api.getCompanies(payload);
                commit('setCompanies', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getSubActivities({commit}, payload) {
            try {
                commit('setLoading', true)
                const response = await api.getSubActivities(payload);
                commit('setSubActivities', response)
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getCountries({commit}, payload) {
            try {
                const response = await api.getCountries(payload);
                commit('setCountries', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
    }
}
