import client from "@/data/api/client";

export default {
    async createPackage(payload) {
        return client.parseResponse(await client.post('/package', payload));
    },
    async createMeasure(payload) {
        return client.parseResponse(await client.post('/measurement', payload));
    },
    async updateMeasure(payload) {
        return client.parseResponse(await client.put('/measurement/' + payload.id, payload));
    },
    async deleteMeasure(payload){
        return client.parseResponse(await client.delete('/measurement/' + payload.id, payload));
    },
    async deletePackage(payload) {
        return client.parseResponse(await client.delete('/package/' + payload.id));
    },
    async updatePackage(payload) {
        return client.parseResponse(await client.put('/package/' + payload.id, payload));
    },
    async getPackages(payload) {
        return client.parseResponse(await client.get('/package', payload))
    },
    async updatePackageCoverImage(payload){
      return client.parseResponse(await client.upload(`/package/cover/${payload.id}`,payload))
    },
    async togglePublishedStatus(payload) {
        return client.parseResponse(await client.post('/package/publish/' + payload.id, payload))
    },
    async getMeasurements(payload) {
        return client.parseResponse(await client.get('/measurement', payload))
    },
    async getPackage(payload) {
        return client.parseResponse(await client.get('/package/' + payload.id, payload))
    },
    async sendBookingCode(payload) {
        return client.parseResponse(await client.post('/booking-code', payload))
    },
    async createBooking(payload) {
        return client.parseResponse(await client.post('/booking', payload));
    },
    async getBooking(id) {
        return client.parseResponse(await client.get('/booking/' + id))
    },
    async updateBooking(payload) {
        return client.parseResponse(await client.put('/booking/' + payload.id, payload))
    },
    async createMessage(payload) {
        return client.parseResponse(await client.post('/booking-message', payload))
    },
    async checkPaymentReceipt(payload) {
        return client.parseResponse(await client.get('/booking-receipt', payload));
    },
    async checkSubscriptionReceipt(payload) {
        return client.parseResponse(await client.get('/subscription-receipt', payload));
    },
    async createSTKPush(payload) {
        return client.parseResponse(await client.post('/lipa-na-mpesa/' + payload.id, payload))
    },
    async savePayment(payload) {
        return client.parseResponse(await client.post('/lipa-na-mpesa/' + payload.booking_id, payload));
    },
    async createShareViaBulkSms(payload) {
        return client.parseResponse(await client.post('/share-package', payload))
    },
    async createBulkPushNotification(payload){
        return client.parseResponse(await client.post('/bulk-push-notification', payload))
    }
}
