import client from "@/data/api/client";

export default {
    async getProfile(payload) {
        return await client.parseResponse(await client.get('/user', payload))
    },
    async getBusinessVerification(payload) {
        return await client.parseResponse(await client.get('/business-verification', payload))
    },
    async completeProfileSetUp(payload){
        return await client.parseResponse(await client.upload('/company-profile',payload,payload.logo))
    },
    async businessVerification(payload){
        return await client.parseResponse(await client.upload('/business-verification',payload,payload.certificate))
    },
    async getTeamMembers(payload){
        return await client.parseResponse(await client.get('/team-member', payload))
    },
    async sendInvitations(payload){
        return await client.parseResponse(await client.post('/team-member', payload))
    },
    async updateYearlySubscription(payload){
        return await client.parseResponse(await client.post('/yearly-subscription', payload))
    },
    async getCompanyRoles(payload) {
        return await client.parseResponse(await client.get('/company-roles', payload))
    },
}
