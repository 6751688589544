import client from "@/data/api/client";

export default {
    async createEvent(payload){
        return client.parseResponse(await client.post('/event',payload))
    },
    async updateEvent(payload){
        return client.parseResponse(await client.put('/event/'+payload.id,payload))
    },
    async getEvents(payload){
        return client.parseResponse(await client.get('/event',payload))
    },
    async guestGetEvents(payload){
        return client.parseResponse(await client.get('/events',payload))
    },
    async getEvent(payload){
        return client.parseResponse(await client.get(`/event/${payload.id}`))
    },
    async guestGetEvent(payload){
        return client.parseResponse(await client.get(`/events/${payload.id}`))
    },
    async deleteEvent(id){
        return client.parseResponse(await client.delete(`/event/${id}`))
    },
    async updateEventPublishedStatus(payload){
        return client.parseResponse(await client.put(`/event/status/${payload.id}`,payload))
    },
    async updateEventCoverImage(payload){
        return client.parseResponse(await client.upload(`/event/cover/${payload.id}`,payload))
    },
    async getGroupedEvents(payload){
        return client.parseResponse(await client.get('/grouped-events',payload))
    },
    async getEventsCalendar(payload){
        return client.parseResponse(await client.get('/events-calendar',payload))
    },
}
