export default [
    {
        path: '/blog',
        name: 'blog',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/blogs/blogs-list.vue'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
    {
        path: '/blog/create/:slug?',
        name: 'blog-create',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/blogs/blogs-create.vue'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
]
