import {initializeApp} from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyCRlxYPHDHMUO9UX_aQzI3_A9aaX5xTNOc",
    authDomain: "vacay-d1066.firebaseapp.com",
    databaseURL: "https://vacay-d1066-default-rtdb.firebaseio.com",
    projectId: "vacay-d1066",
    storageBucket: "vacay-d1066.appspot.com",
    messagingSenderId: "146726236137",
    appId: "1:146726236137:web:b9a65a52fc7f0e29e2e578",
    measurementId: "G-KGW92T1Q80"
}
initializeApp(firebaseConfig)
