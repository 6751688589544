import client from "@/data/api/client";

export default {
    async getContacts(payload) {
        return client.parseResponse(await client.get('/contact', payload));
    },
    async createContact(payload) {
        return client.parseResponse(await client.post('/contact', payload))
    },
    async updateContact(payload) {
        return client.parseResponse(await client.put('/contact/' + payload.id, payload))
    },
    async deleteContact(payload) {
        return client.parseResponse(await client.delete('/contact/' + payload.id))
    },
    async getContactDetails(payload) {
        return client.parseResponse(await client.get('/contact-detail', payload));
    },
    async createContactDetail(payload) {
        return client.parseResponse(await client.post('/contact-detail', payload))
    },
    async deleteContactDetail(payload) {
        return client.parseResponse(await client.delete('/contact-detail/' + payload.id))
    },
    async uploadContactDetails(payload){
        return client.parseResponse(await client.upload('/contact-detail-upload',payload));
    }
}
