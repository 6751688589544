import client from "@/data/api/client";

export default {
    async getBulkSms(payload) {
        return client.parseResponse(await client.get('/bulk-sms', payload))
    },
    async createBulkSms(payload) {
        return client.parseResponse(await client.post('/bulk-sms', payload))
    },
    async updateBulkSms(payload) {
        return client.parseResponse(await client.put('/bulk-sms/' + payload.id, payload))
    },
    async sendBulkSms(payload) {
        return client.parseResponse(await client.post('/send-bulk-sms', payload))
    },
    async getSms(payload) {
        return client.parseResponse(await client.get('/bulk-sms/' + payload.id, payload))
    },
    async getBulkSmsContact(payload) {
        return client.parseResponse(await client.get('/bulk-sms-contact', payload))
    },
    async createBulkSmsContact(payload) {
        return client.parseResponse(await client.post('/bulk-sms-contact', payload))
    },
    async uploadContacts(payload) {
        return client.parseResponse(await client.upload('/bulk-sms-contact-upload', payload, payload.file))
    },
    async deleteBulkSmsContact(payload) {
        return client.parseResponse(await client.delete('/bulk-sms-contact/' + payload.id))
    },
    async deleteBulkSms(payload) {
        return client.parseResponse(await client.delete('/bulk-sms/' + payload.id, payload))
    },
    async getBulkContacts(payload) {
        return client.parseResponse(await client.get('/bulk-contact', payload))
    },
    async saveContactsFromBulkContacts(payload){
        return client.parseResponse(await client.post('/bulk-contact', payload))
    }
}
