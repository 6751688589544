import auth from './auth.api'
import profile from './profile.api';
import destinations from './destinations.api'
import packages from './packages.api'
import bulk_sms from './bulk_sms.api'
import surveys from './surveys.api'
import contacts from './contacts.api'
import bookings from './bookings.api';
import socials from './socials.api';
import general from './general.api'
import wallet from './wallet.api'
import pricing from './pricing.api'
import settings from './settings.api';
import reports from './reports.api';
import explore from "@/data/api/explore.api";
import events from './events.api'
import blogs from './blogs.api'
export default {
    ...auth,
    ...profile,
    ...destinations,
    ...packages,
    ...bulk_sms,
    ...surveys,
    ...contacts,
    ...bookings,
    ...socials,
    ...general,
    ...wallet,
    ...pricing,
    ...settings,
    ...reports,
    ...packages,
    ...explore,
    ...events,
    ...blogs
}
