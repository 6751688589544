export default [
    {
        path: '/surveys',
        name: 'surveys',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/surveys/surveys-list'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
    {
        path: '/surveys/questions/:id',
        name: 'surveys-questions',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/surveys/survey-questions-list'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
    {
        path: '/surveys/fill/form/:id',
        name: 'survey-preview',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/surveys/survey-form'),
        },
        meta: {
            guest: true,
            dont_show_header: true
        },
    },
];
