import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        cash_movements: {data: [], per_page: 10, total: 0},
        loading: false,
        excel_loading: false,
        booking_report: null,
        bulk_sms_report: null,
        revenue_report: null
    },
    getters: {
        cash_movements: state => state.cash_movements,
        loading: state => state.loading,
        excel_loading: state => state.excel_loading,
        booking_report: state => state.booking_report,
        bulk_sms_report: state => state.bulk_sms_report,
        revenue_report: state => state.revenue_report
    },
    mutations: {
        setCashMovements(state, payload) {
            state.cash_movements = payload;
        },
        setLoading(state, status) {
            state.loading = status;
        },
        setExcelLoading(state,status){
            state.excel_loading = status;
        },
        setBookingReport(state,data){
            state.booking_report = data;
        },
        setBulkSMSReport(state,data){
            state.bulk_sms_report = data;
        },
        setRevenueReport(state,data){
            state.revenue_report = data;
        }
    },
    actions: {
        async getBookingReport({commit}, payload) {
            try {
                commit('setLoading', true)
                const response = await api.getBookingReport(payload);
                commit('setBookingReport', response);
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getBulkSMSReport({commit}, payload) {
            try {
                commit('setLoading', true)
                const response = await api.getBulkSMSReport(payload);
                commit('setBulkSMSReport', response);
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getRevenueReport({commit}, payload) {
            try {
                commit('setLoading', true)
                const response = await api.getRevenueReport(payload);
                commit('setRevenueReport', response);
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getCashMovements({commit}, payload) {
            try {
                commit('setLoading', true)
                const response = await api.getCashMovements(payload);
                commit('setCashMovements', response);
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        },
        async exportCashMovements({commit},payload){
            try {
                commit('setExcelLoading',true);
                let date = new Date().toLocaleString()
                const response = await api.exportCashMovements(payload)
                const url = window.URL.createObjectURL(new Blob([response]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `cash_movements_${date}.csv`);
                document.body.appendChild(link);
                link.click();
                commit('setExcelLoading',false);
            }catch (e) {
                commit('setExcelLoading',false);
                this._vm.$message.error(e.response.data.message)
            }
        }
    }
}
