export default [
    {
        path: '/contacts',
        name: 'contacts',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/contacts/contacts-list'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
    {
        path: '/contact/details/:id',
        name: 'contact-details',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/contacts/contact-details-list'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
]
