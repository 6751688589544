export default [
    {
        path: '/profile',
        name: 'profile setup',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/profile/profile-setup'),
        },
        meta: {
            auth: true
        },
    },
    {
        path: '/business/verification',
        name: 'business verification',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/profile/business-verification'),
        },
        meta: {
            auth: true
        },
    }
];
