import Vue from 'vue'
import VueRouter from 'vue-router'
import authentication from './auth.router'
import dashboard from './dashboard.router'
import profile from "./profile.router";
import destinations from './destinations.router';
import packages from './packages.router';
import bulk_sms from './bulks_sms.router'
import surveys from './surveys.router';
import reports from './reports.router'
import contacts from './contacts.router';
import bookings from './bookings.router';
import socials from './socials.router';
import wallet from './wallet.router'
import pricing from './pricing.router'
import settings from './settings.router';
import general from './general.router';
import operators from './operators.router'
import events from './events.router'
import blogs from './blogs.router'
import authMiddleware from "./middlewares/auth-middleware";
import redirectMiddleware from "@/router/middlewares/redirect-middleware";
import store from "@/data/store";
Vue.use(VueRouter)
const routes = [
    ...authentication,
    ...dashboard,
    ...profile,
    ...destinations,
    ...packages,
    ...bulk_sms,
    ...surveys,
    ...reports,
    ...contacts,
    ...bookings,
    ...socials,
    ...wallet,
    ...pricing,
    ...settings,
    ...general,
    ...operators,
    ...events,
    ...blogs,
    {
        path: '*',
        redirect: '/',
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})
router.beforeEach((to, from, next) => {
    let result;
    if (to.meta.auth) {
        result = authMiddleware(to, next);
    }else if(to.meta.redirect_if_authenticated){
       result = redirectMiddleware(to,next)
    }else{
        result = next();
    }
    store.commit('setProfileDropdownHidden',true)
    store.commit('setMobileMenuHidden',true)
    return result;
});

export default router
