import client from "@/data/api/client";

export default {
    async signUp(payload) {
        return client.parseResponse(await client.post('/account', payload))
    },
    async signIn(payload) {
        return client.parseResponse(await client.post('/login', payload))
    },
    async signOut(payload) {
        return client.parseResponse(await client.post('/sign-out', payload))
    },
    async resetPassword(payload) {
        return client.parseResponse(await client.post('/reset/password', payload))
    },
    async recoverPassword(payload) {
        return client.parseResponse(await client.post('/recover/password', payload))
    },
    async getInvitation(payload) {
        return client.parseResponse(await client.get('/team-member/' + payload.token))
    },
    async updateInvitation(payload) {
        return client.parseResponse(await client.put('/team-member/' + payload.id, payload))
    }
}
