export default  [
    {
        path: '/explore-events/:slug?',
        name: 'explore events',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/events/explore-events.vue'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true,
        },
    },
    {
        path: '/event-details/:id/:slug',
        name: 'event details',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/explore/event-details.vue'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true,
        },
    },
    {
        path: '/events',
        name: 'events',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/events/events-list.vue'),
        },
        meta: {
            auth: true,
            // subscribed: true
        },
    },
    {
        path: '/events/create/:id?',
        name: 'events-create',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/events/event-create.vue'),
        },
        meta: {
            auth: true,
            // subscribed: true,
        }
    },
    {
        path: '/events/booking/:id/:slug',
        name: 'events-booking',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/events/event-booking.vue'),
        },
        meta: {
            showPlayStoreBanner: true,
            guest: true
        },
    },
];
