export default [
    {
        path: '/payment/callback',
        name: 'payment callback',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/pricing/payment-callback'),
        },
        meta: {
            auth: true,
        },
    },
    {
        path: '/pricing',
        name: 'pricing',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/pricing/plan-pricing'),
        },
        meta: {
            guest: true,
        },
    },
    {
        path: '/plan/subscription/:id',
        name: 'plan subscription',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/pricing/plan-subscription'),
        },
        meta: {
            auth: true,
        },
    },
];
