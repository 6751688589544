export default [
    {
        path: '/socials/whatsapp',
        name: 'whatsapp',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/socials/whatsapp/whatsapp-chat.vue'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
    {
        path: '/whatsapp/accounts/templates/:id',
        name: 'whatsapp-templates',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/socials/whatsapp/whatsapp-template.vue'),
        },
        meta: {
            auth: true,
        },
    },
    {
        path: '/socials/post',
        name: 'socials post',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/socials/post/socials-post.vue'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
];
