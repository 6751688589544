import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        plans: [],
        plan: {}
    },
    getters: {
        plans: state => state.plans,
        plan: state => state.plan
    },
    mutations: {
        setPlans(state, data) {
            state.plans = data;
        },
        setPlan(state, data) {
            state.plan = data;
        }
    },
    actions: {
        async getPlan({commit}, id) {
            try {
                const plan = await api.getPlan(id);
                commit('setPlan', plan);
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getPlans({commit}, payload) {
            try {
                const response = await api.getPlans(payload);
                commit('setPlans', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async subscribeCompany(context, payload) {
            try {
                const response = await api.subscribeCompany(payload);
                const {data} = JSON.parse(response);
                window.location.href = data.authorization_url
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async cancelSubscription(context, payload) {
            return await api.cancelSubscription(payload);
        }
    }
}
