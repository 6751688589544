export default [
    {
        path: '/cash-movement',
        name: 'cash movement',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/revenue/cash-movement'),
        },
        meta: {
            auth: true
        },
    },
    {
        path: '/reports',
        name: 'reports',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/reports/dashboard-page'),
        },
        meta: {
            auth: true,
            // subscribed: true
        },
    },
    {
        path: '/reports/surveys',
        name: 'reports-surveys',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/reports/surveys/summarized-report'),
        },
        meta: {
            auth: true,
            // subscribed: true
        },
    },
    {
        path: '/reports/bookings',
        name: 'reports-bookings',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/reports/bookings/bookings-report'),
        },
        meta: {
            auth: true,
            // subscribed: true
        },
    },
    {
        path: '/reports/revenue',
        name: 'reports-revenue',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/reports/revenue/revenue-report'),
        },
        meta: {
            auth: true,
            // subscribed: true
        },
    },
    {
        path: '/reports/bulk/sms',
        name: 'reports-bulk-sms',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/reports/sms/bulk-sms-report'),
        },
        meta: {
            auth: true,
            // subscribed: true
        },
    },
];
