import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        profile: {},
        verification: {},
        loading: false,
        team_members: [],
        company_roles:[]
    },
    getters: {
        profile: state => state.profile,
        verification: state => state.verification,
        team_members: state => state.team_members,
        loading: state => state.loading,
        company_roles: state => state.company_roles,
    },
    mutations: {
        setProfile(state, data) {
            state.profile = data;
        },
        setVerification(state, data) {
            state.verification = data;
        },
        setTeamMembers(state, data) {
            state.team_members = data;
        },
        setLoading(state, data) {
            state.loading = data;
        },
        setCompanyRoles(state, data) {
            state.company_roles = data;
        },
    },
    actions: {
        async getCompanyRoles({commit}, payload) {
            try {
                const response = await api.getCompanyRoles(payload)
                commit('setCompanyRoles', response)
                localStorage.setItem('company_roles', JSON.stringify(response))
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getProfile({commit}, payload) {
            try {
                const response = await api.getProfile(payload)
                commit('setProfile', response)
                localStorage.setItem('company',JSON.stringify(response.company))
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async completeProfileSetUp(context, payload) {
            try {
                const {message, verification} = await api.completeProfileSetUp(payload);
                const route = localStorage.getItem('path')
                if (route) {
                    window.location.href = route
                    localStorage.removeItem('path')
                } else {
                    if (!verification) {
                        window.location.href = '/business/verification'
                    } else {
                        window.location.href = '/dashboard'
                    }
                }
                this._vm.$message.success(message)

            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async businessVerification(context, payload) {
            return await api.businessVerification(payload);
        },
        async getBusinessVerification({commit}, payload) {
            try {
                const response = await api.getBusinessVerification(payload)
                commit('setVerification', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async getTeamMembers({commit}, payload) {
            try {
                commit('setLoading', true)
                const response = await api.getTeamMembers(payload)
                commit('setTeamMembers', response)
                commit('setLoading', false)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
                commit('setLoading', false)
            }
        },
        async sendInvitations(context, payload) {
            return await api.sendInvitations(payload);
        },
        async updateYearlySubscription(context,payload){
            return await api.updateYearlySubscription(payload);
        }
    }
}
