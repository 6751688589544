import client from "@/data/api/client";

export default {
    async sendWhatsappMessage(payload) {
        return client.parseResponse(await client.post('/whatsapp-chat', payload))
    },
    async markMessageAsRead(payload) {
        return client.parseResponse(await client.post('/whatsapp-read', payload))
    },
    async sendWhatsappTemplate(payload) {
        return client.parseResponse(await client.post('/whatsapp-chat-template', payload))
    },
    async sendMessageReaction(payload) {
        return client.parseResponse(await client.post('/whatsapp-chat-reaction', payload))
    },
    async sendWhatsappFile(payload) {
        return client.parseResponse(await client.post('/whatsapp-file', payload))
    },
    async createMetaPost(payload) {
        return client.parseResponse(await client.post('/meta-post', payload));
    },
    async getMetaPosts(payload) {
        return client.parseResponse(await client.get('/meta-post', payload))
    },
    async getPostComments(payload) {
        return client.parseResponse(await client.get('/post-comments/' + payload.id,payload))
    },
    async postCommentReply(payload) {
        return client.parseResponse(await client.post('/post-comment-reply/' + payload.comment_id, payload))
    },
    async postComment(payload) {
        return client.parseResponse(await client.post('/post-comments/' + payload.post_id, payload))
    },
    async getPageProfile(payload) {
        return client.parseResponse(await client.get('/page-profile/' + payload.page_id))
    },
}
