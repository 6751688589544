import client from "@/data/api/client";

export default {
    async getWalletBalance(payload) {
        return client.parseResponse(await client.get('/wallet/' + payload.company_id, payload))
    },
    async checkWalletPaymentReceipt(payload) {
        return client.parseResponse(await client.get('/wallet-receipt', payload));
    },
    async convertWalletCurrency(payload){
        return client.parseResponse(await client.get('/currency-converter', payload));
    },
    async sendWalletStkPush(payload) {
        return client.parseResponse(await client.post('/wallet-stk', payload));
    },
    async getWalletTransactions(payload) {
        return client.parseResponse(await client.get('/wallet', payload))
    }
}
