export default [
    {
        path: '/bulk-sms',
        name: 'bulk-sms',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/sms/bulk-sms'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    },
    {
        path: '/bulk-sms/:id/contact',
        name: 'bulk-sms-contact',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/sms/bulk-sms-contact'),
        },
        meta: {
            auth: true,
            subscribed: true
        },
    }
];
