import api from "@/data/api";

export default {
    namespaced: true,
    state: {
        balance: null,
        wallet_transactions: {data: [], per_page: 10, total: 0},
        loading: false
    },
    getters: {
        balance: state => state.balance,
        wallet_transactions: state => state.wallet_transactions,
        loading: state => state.loading
    },
    mutations: {
        setBalance(state, balance) {
            state.balance = balance
        },
        setWalletTransactions(state, data) {
            state.wallet_transactions = data;
        },
        setLoading(state, status) {
            state.loading = status;
        }
    },
    actions: {
        async sendWalletStkPush(context, payload) {
            return api.sendWalletStkPush(payload);
        },
        async getWalletBalance({commit}, payload) {
            try {
                const response = await api.getWalletBalance(payload);
                commit('setBalance', response)
            } catch (e) {
                this._vm.$message.error(e.response.data.message)
            }
        },
        async checkPaymentReceipt(context, payload) {
            return await api.checkWalletPaymentReceipt(payload);
        },
        async convertCurrency(context,payload){
            return await api.convertWalletCurrency(payload);
        },
        async getWalletTransactions({commit}, payload) {
            commit('setLoading', true)
            try {
                const response = await api.getWalletTransactions(payload)
                commit('setWalletTransactions', response)
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading', false)
                this._vm.$message.error(e.response.data.message)
            }
        }
    }
}
