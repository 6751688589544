import client from "@/data/api/client";

export default {
    async getSurveySummarizedReport(payload) {
        return client.parseResponse(await client.get('/survey-response', payload))
    },
    async getFlows(payload){
        return client.parseResponse(await client.get('/flow', payload))
    },
    async getSurveys(payload) {
        return client.parseResponse(await client.get('/survey', payload))
    },
    async createSurvey(payload) {
        return client.parseResponse(await client.post('/survey', payload))
    },
    async createWhatsAppFlow(payload) {
        return client.parseResponse(await client.post('/flow', payload))
    },
    async publishWhatsAppFlow(payload) {
        return client.parseResponse(await client.post(`/flow/publish/${payload.flow_id}`, payload))
    },
    async updateWhatsAppFlow(payload) {
        return client.parseResponse(await client.put(`/flow/${payload.id}`))
    },
    async getSurvey(payload) {
        return client.parseResponse(await client.get('/survey/' + payload.id, payload))
    },
    async createSurveyResponse(payload) {
        return client.parseResponse(await client.upload('/survey-response', payload))
    },
    async updateSurvey(payload) {
        return client.parseResponse(await client.put('/survey/' + payload.id, payload))
    },
    async deleteSurvey(payload) {
        return client.parseResponse(await client.delete('/survey/' + payload.id, payload))
    },
    async createSurveyQuestion(payload) {
        return client.parseResponse(await client.post('/survey-question', payload))
    },
    async getSurveyQuestions(payload) {
        return client.parseResponse(await client.get('/survey-question', payload))
    },
    async updateSurveyQuestion(payload) {
        return client.parseResponse(await client.put('/survey-question/' + payload.id, payload))
    },
    async deleteSurveyQuestion(payload) {
        return client.parseResponse(await client.delete('/survey-question/' + payload.id, payload))
    }
}
