export default [
    {
        path: '/team/invitation/:token',
        name: 'team invite',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/team-invite.vue'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/sign-in',
        name: 'sign in',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/sign-in'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/sign-up',
        name: 'sign up',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/sign-up'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/reset',
        name: 'reset password',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/reset-password'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/verify-email',
        name: 'verify email',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/verify-email'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/reset-email',
        name: 'reset email',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/reset-email'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/reset-password/:token',
        name: 'recover password',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/recover-password'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    }
];
